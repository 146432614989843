
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Features from '~/components/Features.vue'
import Banner from '~/components/Banner.vue'

@Component({
  components: {
  Banner,
  Features
  }
  })
export default class extends Vue {
  @Prop() error!: any
}
